// Components
export enum TRACKING_COMPONENTS {
    NAV_BAR = 'Nav',
    HEADER = 'Header',
    USER_MENU = 'User menu',
    PREFERENCES = 'Preferences',
    HOME = 'Home',
    JOBS = 'Jobs',
    CONFIGURATION = 'Configuration',
    CONFIGURATION_LOGGING = 'Configuration_Logging',
    CONFIGURATION_LISTS = 'Configuration_Lists',
    PROJECTS_LANDING = 'Projects landing',
    PROJECT_OVERVIEW = 'Project overview',
    DRILLHOLE_LANDING = 'Drill hole landing',
    SITE_SETTINGS_LIST_LANDING = 'Site settings list landing',
    SITE_SETTINGS_SAMPLE_LIST_LANDING = 'Site settings sample list landing',
    SITE_SETTINGS_TABLE_LANDING = 'Site settings table landing',
    SITE_SETTINGS_HEADER_LANDING = 'Site settings header landing',
    SITE_SETTINGS_ACTIVITY_LANDING = 'Site settings activity landing',
    SITE_SETTINGS_SAMPLE_DISPATCH_HEADERS = 'Site settings sample dispatch header landing',
    SITE_SETTINGS_SAMPLE_ANALYSIS_WORKFLOWS = 'Site settings sample analysis workflow landing',
    SITE_SETTINGS = 'Site settings',
    EVENTS_LANDING = 'Events landing',
    DATA_LANDING = 'Data landing',
    POINTS_LANDING = 'Point landing',
    PROPERTIES_PANEL = 'Properties',
    DATA_ENTRY = 'Data entry',
    KEYBOARD_NAVIGATION = 'Keyboard nav',
    DIALOG = 'Dialog',
    MAP = 'Map',
    NOTIFICATIONS = 'Notifications',
    TAB_NAVIGATION = 'Tab navigation',
    ROW_MENU = 'row menu',
    FILTER_MENU = 'filter menu',
    LIST_VIEW = 'list view',
    CARD_VIEW = 'card view',
    ROW_ACTION = 'row action',
    COORDINATES = 'coordinates',
    COLUMNS_TOOL_PANEL = 'columns tool panel',
    FILTERS_TOOL_PANEL = 'filters tool panel',
    NONE = 'None',
}

// Event Types
export enum EVENT_TYPES {
    VISIT = 'visited',
    COLLAPSE = 'collapsed',
    EXPAND = 'expanded',
    OPEN = 'opened',
    CLOSE = 'closed',
    CHANGE = 'changed',
    SIGN_OUT = 'signed out',
    SORT = 'sorted',
    DOWNLOAD = 'downloaded',
    SWITCH_VIEW = 'switched to',
    PIN = 'pinned',
    UNPIN = 'unpinned',
    SELECT = 'selected',
    CREATE = 'created',
    ADD = 'added',
    REMOVE = 'removed',
    CLEAR = 'cleared',
    CANCEL = 'canceled',
    APPLY = 'applied',
    DELETE = 'deleted',
    CLONE = 'cloned',
    UNASSIGN = 'unassigned',
    EXPORT = 'exported',
    IMPORT = 'imported',
    COPY = 'copied',
    JUMP = 'jumped',
    USE = 'used',
    SEARCH = 'searched',
    ADJUST = 'adjusted',
    DRAG = 'dragged',
    ENABLE = 'enable',
    DISABLE = 'disabled',
    REORDER = 'reordered',
    CLICK = 'clicked',
    UNSELECT = 'unselected',
    NAVIGATE = 'navigated',
    RENAME = 'renamed',
    FILTER = 'filtered',
}

export enum DIALOGS {
    CREATE = 'create',
    CHANGE_STATE = 'change',
    DELETE = 'delete',
    UNASSIGN = 'unassign',
    EXPORT = 'export',
    CLONE = 'clone',
    RENAME = 'rename',
    CREATE_ROWS = 'create rows',
    COPY_INTERVALS = 'copy intervals',
    JUMP_TO_DEPTH = 'jump to depth',
    SPLIT_INTERVAL = 'split interval',
    MERGE_INTERVALS = 'merge intervals',
    SORT_ROWS = 'sort rows',
}
